import React from 'react'

export default class RemoveCreditsForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            numberOfMainCredits: 0,
            numberOfProCredits: 0,
            numberOfSmpCredits: 0
        }
    }

    onInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
          [name]: value
        });
    }

    getProductType() {
        switch (this.props.mainProductId) {
            case 6:
                return 'Subscription'
            default:
                return 'Publish'
        }
    }

    render() {
        return (
            <div className="flex">
                <form action="/purchases/remove_credits" method="POST">
                    <input type="hidden" name="authenticity_token" value={document.getElementsByName('csrf-token')[0].content} />
                    <input type="hidden" name="_method" value="DELETE" />
                    <input type="hidden" name="billing_company_id" value={this.props.companyId} />
                    <input type="hidden" name="mainProductId" value={this.props.mainProductId} />
                    <div className="row">
                        <div className="column">
                            <h4>{this.getProductType()} Credits</h4>
                            <input type="number" name="numberOfMainCredits" onChange={this.onInputChange} value={this.state.numberOfMainCredits} min="0" max={this.props.mainProductMaxCount} step="1" />
                        </div>
                        <div className="column">
                            <h4>Pro Credits</h4>
                            <input type="number" name="numberOfProCredits" onChange={this.onInputChange} value={this.state.numberOfProCredits} min="0" max={this.props.proMaxCount} step="1" />
                        </div>
                        <div className="column">
                            <h4>SMP Credits</h4>
                            <input type="number" name="numberOfSmpCredits" onChange={this.onInputChange} value={this.state.numberOfSmpCredits} min="0" max={this.props.smpMaxCount} step="1" />
                        </div>
                    </div>
                    <div className="row">
                        <input type="submit" className="danger" value="Remove Credits" />
                    </div>
                </form>
            </div>
        )
    }
}
