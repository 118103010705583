import Rails from '@rails/ujs'
import swal from 'sweetalert2'

// Override Rails handling of Confirms
const handleConfirm = (elem) => {
    Rails.stopEverything(elem)

    const element = elem.srcElement
    const message = element.getAttribute('data-confirm-custom')
    const text = element.getAttribute('data-text')

    swal({
      title: message || 'Are you sure?',
      text: text || '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, I am sure.',
      cancelButtonText: 'Cancel'
    }).then(result => confirmed(element, result))
}

const handleConfirmWithInput = (elem) => {
    Rails.stopEverything(elem)

    const element = elem.srcElement
    const message = element.getAttribute('data-confirm-with-input')
    const text = element.getAttribute('data-text')
    const confirmWord = element.getAttribute('data-confirm-word')

    swal({
      title: message || 'Are you sure?',
      text: text || '',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Yes, I am sure.',
      confirmButtonColor: "#DD6B55",
      cancelButtonText: 'Cancel',
      inputPlaceholder: 'Type ' + confirmWord + ' to remove',
      inputValidator: (value) => {
        return new Promise((resolve) => {
            if (value === confirmWord) {
                resolve()
            } else {
                resolve('To continue with this action you need to write ' + confirmWord)
            }
        })
      }
    }).then(result => confirmedWithValue(element, result, confirmWord))
}

const confirmed = (element, result) => {
    if (result.value) {
        // User clicked confirm button
        element.removeAttribute('data-confirm-custom')
        element.click()
    }
}

const confirmedWithValue = (element, result, confirmWord) => {
    if (result.value && result.value === confirmWord) {
        // User clicked confirm button
        element.removeAttribute('data-confirm-with-input')
        element.click()
    }
}


// Add event listener before the other Rails event listeners like the one for `method: :delete`
Rails.delegate(document, 'a[data-confirm-custom]', 'click', handleConfirm)
Rails.delegate(document, 'a[data-confirm-with-input]', 'click', handleConfirmWithInput)
