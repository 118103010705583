import React from 'react'
import ReactDOM from 'react-dom'
import Autosuggest from 'react-autosuggest'

import CompanySearchErrorBoundary from './CompanySearchErrorBoundary'

if(document.getElementById('company-search')) {
    function getSuggestionValue(suggestion) {
        return suggestion.id + ''
    }

    function renderSuggestion(suggestion) {
        return <span>{suggestion.company_name} / ID: {suggestion.id}</span>
    }

    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    function onSuggestionSelected(event, {suggestionValue}) {
        window.location = '/billing_companies/' + suggestionValue
    }

    class CompanySearch extends React.Component {
        constructor(props) {
            super(props)
            this.state = {companies: [], suggestions: [], value: ''}
            this.onChange = this.onChange.bind(this)
            this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
            this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
        }

        componentDidMount() {
            fetch('/billing_companies/search_suggestions').then(data => {
                return data.json()
            }).catch(e => {
                throw new Error(e, 'Could not fetch /billing_companies/search_suggestions')
            }).then(json => {
                this.setState({companies: json, value: ''})
            })
        }

        onChange(_, { newValue }) {
            this.setState({
                value: newValue
            })
        }

        onSuggestionsFetchRequested({ value }) {
            this.setState({
                suggestions: this.getSuggestions(value)
            })
        }

        onSuggestionsClearRequested() {
            this.setState({
                suggestions: []
            })
        }

        getSuggestions(value) {
            const escapedValue = escapeRegexCharacters(value.trim())

            if (escapedValue === '') {
                return []
            }
            
            const regex = new RegExp(escapedValue, 'i')
            const regexForId = new RegExp('^' + escapedValue, 'i')

            return [...this.state.companies.filter(company => regex.test(company.company_name)), 
                ...this.state.companies.filter(company => regexForId.test(company.id))]
        }

        render() {
            const { value, suggestions } = this.state;
            const inputProps = {
                placeholder: "Search for a Company Name",
                value,
                onChange: this.onChange
            }
            return (
                <div className="company-search">
                    <h3>Search for Company</h3>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        onSuggestionSelected={onSuggestionSelected}
                    />
                </div>
            )
        }
    }

    ReactDOM.render(<CompanySearchErrorBoundary><CompanySearch /></CompanySearchErrorBoundary>, document.getElementById('company-search'))
}
