import React from 'react'
import Modal from 'react-modal'
import CancelContractForm from './CancelContractForm'
import ActivateContractForm from './ActivateContractForm'

export default class CompanyInformation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            modalIsOpen: false,
            cancelModalIsOpen: false,
            ticksNow: Date.now()
        }
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

    openCancelModal = () => {
        this.setState({cancelModalIsOpen: true})
    }

    closeCancelModal = () => {
        this.setState({cancelModalIsOpen: false})
    }

    getCompanyType() {
        if(this.props.companyType == 'subscription') {
            return 'Subscription Customer'
        } else {
            return 'Regular Customer'
        }
    }

    getContractDetails() {
        if(this.props.contractEndDate == null) {
            return 'active'
        }

        const contractEndTicks = new Date(this.props.contractEndDate).getTime()

        if (contractEndTicks < this.state.ticksNow) {
            return 'inactive'
        } else {
            return 'limbo'
        }
    }

    render() {
        return (
            <div className="column company-information">
                <h1>{this.props.companyName}</h1>
                <p>
                    ID: {this.props.companyId} / {this.getCompanyType()}
                </p>
                {this.props.companyType == 'subscription' && (
                    <div>
                        <strong>Contract details</strong><br/>
                        {this.getContractDetails() == 'active' && (
                            <div>
                                <span className="active">ACTIVE</span>
                                <a className="cancel-contract-link" onClick={this.openCancelModal}>Cancel contract</a>
                                <Modal
                                    isOpen={this.state.cancelModalIsOpen}
                                    onRequestClose={this.closeCancelModal}
                                    contentLabel="Cancel Contract"
                                    className="Modal"
                                    overlayClassName="Overlay"
                                >
                                    <CancelContractForm companyId={this.props.companyId} />
                                </Modal>
                            </div>
                        )}
                        {(this.getContractDetails() == 'inactive' || this.getContractDetails() == 'limbo') && (
                            <div>
                                {this.getContractDetails() == 'inactive' && (<span className="inactive">CANCELLED</span>)}
                                {this.getContractDetails() == 'limbo' && (<span className="to-be-cancelled">TO BE CANCELLED</span>)}
                                <br/><span className="contract-end-date">End date: {this.props.contractEndDate.slice(0,10)}</span>
                                <a className="activate-contract-link" onClick={this.openModal}>Re-activate contract</a>
                                <Modal
                                    isOpen={this.state.modalIsOpen}
                                    onRequestClose={this.closeModal}
                                    contentLabel="Re-activate Contract"
                                    className="Modal"
                                    overlayClassName="Overlay"
                                >
                                    <ActivateContractForm companyId={this.props.companyId} />
                                </Modal>
                            </div>
                        )}
                    </div>
                )}
                <p>
                    <strong>API-key</strong><br/>
                    {this.props.apiKey}
                </p>
                <p>
                    <a href={`/billing_companies/${this.props.companyId}/edit`}>Edit company</a> |
                    <a href={`/integration_configs?billing_company_id=${this.props.companyId}`}>Configure integrations</a> |
                    <a href={`/billing_companies/${this.props.companyId}/stats`}>Statistics</a> |
                    <a href={`/billing_companies/${this.props.companyId}/billing_company_products`}>Manage products</a>
                </p>
            </div>
        )
    }
}
