import React from 'react'
import ReactDOM from 'react-dom'
import ContractMenu from './ContractMenu'

let contractDashboard = document.getElementById('react-contracts-dashboard')

if(contractDashboard) {
    class ContractDashboard extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                activeView: 'active'
            }
        }

        render() {
            return (
                <div>
                    <div className="row">
                        <ContractMenu />
                    </div>
                </div>
            )
        }
    }

    ReactDOM.render(<ContractDashboard/>, contractDashboard)
}
