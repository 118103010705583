import React from 'react'
import ReactDOM from 'react-dom'
import CompanyInformation from './CompanyInformation'
import CompanyCreditsBalance from './CompanyCreditsBalance'
import VacancyListMenu from './VacancyListMenu'

let companyContainer = document.getElementById('react-company-container')

if(companyContainer) {
    class CompanyContainer extends React.Component {
        constructor(props) {
            super(props)
            this.state = {
                data: JSON.parse(companyContainer.dataset.reactProps)
            }
        }

        render() {
            const { company } = this.state.data
            return (
                <div>
                    <div className="row">
                        <CompanyInformation companyType={company.company_type} companyName={company.company_name} companyId={this.state.data.companyId} apiKey={company.api_key} contractEndDate={company.contract_end_date} />
                        <CompanyCreditsBalance companyType={company.company_type} mainProductId={this.state.data.mainProductId} companyId={this.state.data.companyId} subscriptionEndDate={this.state.data.subscriptionEndDate} unusedCredits={company.unused_credits} contractHasEnded={this.state.data.contractHasEnded} />
                    </div>
                    <VacancyListMenu vacancyCounts={this.state.data.company.vacancy_counts} companyId={this.state.data.company.id} companyType={this.state.data.company.company_type} />
                </div>
            )
        }
    }

    ReactDOM.render(<CompanyContainer/>, companyContainer)
}
