import React from 'react'
import VacancyListItem from './VacancyListItem'

const VacancyList = (props) => {
    const { jobList } = props
    return (
        <div>
            {jobList && jobList.map(job => <VacancyListItem companyId={props.companyId} companyType={props.companyType} activeList={props.activeList} job={job} key={job.id} />)}
        </div>
    )
}

export default VacancyList
