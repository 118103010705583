import React from 'react'
import ContractList from './ContractList'

export default class ContractMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = { currentView: 'active', activeContractsList: null, terminatedContractsList: null }
    }

    componentDidMount() {
        this.onMenuItemClick('active', null)
    }

    onMenuItemClick = (list, e) => {
        if(this.state[list + 'ContractsList'] == null) {
            fetch(`/billing_companies/contracts.json?filter=${list == 'terminated' ? 'expired' : ''}`).then(data => {
                return data.json()
            }).catch(() => {
                console.error('jag fick inte data :(')
            }).then(json => {
              console.log(json)
                this.setState(prevState => {
                    prevState.currentView = list
                    prevState[list+'ContractsList'] = json
                    return prevState
                })
            })
        } else {
            this.setState({currentView: list})
        }
    }

    render() {
        return (
            <section>
                <div className="row menu">
                    <div className={`column menu__item ${this.state.currentView == 'active' && 'current'}`} onClick={(e) => this.onMenuItemClick('active', e)}>
                        Active Contracts
                    </div>
                    <div className={`column menu__item ${this.state.currentView == 'terminated' && 'current'}`} onClick={(e) => this.onMenuItemClick('terminated', e)}>
                        Terminated Contracts
                    </div>
                </div>
                <ContractList currentView={this.state.currentView} contractsList={this.state[this.state.currentView + 'ContractsList']} />
            </section>
        )
    }
}
