import React from 'react'

export default class CancelContractForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contractEndDate: new Date().toISOString().slice(0,10)
        }
    }

    onInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
          [name]: value
        });
    }

    render() {
        return (
            <div className="flex">
                <form action={`/billing_companies/${this.props.companyId}/update_contract`} method="POST">
                    <input type="hidden" name="authenticity_token" value={document.getElementsByName('csrf-token')[0].content} />
                    <div className="row">
                        <div className="column">
                            <h4>Contract end date</h4>
                            <p>
                                Set the date when contract ends and all job ads should be unpublished.
                            </p>
                            <input type="date" name="contractEndDate" onChange={this.onInputChange} value={this.state.contractEndDate} />
                        </div>
                    </div>
                    <div className="row">
                        <input type="submit" className="danger" value="Cancel Contract" />
                    </div>
                </form>
            </div>
        )
    }
}
