import React from 'react'
import ContractListItem from './ContractListItem'

const ContractList = (props) => {
    const { contractsList } = props
    return (
        <table>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Contract End Date</th>
              <th className="tight"></th>
            </tr>
          </thead>
          <tbody>
            {contractsList && contractsList.map(contract => <ContractListItem contract={contract} key={contract.id} />)}
          </tbody>
        </table>
    )
}

export default ContractList
