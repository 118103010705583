import React from 'react'

export default class ActivateContractForm extends React.Component {
    constructor(props) {
        super(props)
    }

    onInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
          [name]: value
        });
    }

    render() {
        return (
            <div className="flex">
                <form action={`/billing_companies/${this.props.companyId}/update_contract`} method="POST">
                    <input type="hidden" name="authenticity_token" value={document.getElementsByName('csrf-token')[0].content} />
                    <input type="hidden" name="contractEndDate" value="" />
                    <div className="row">
                        <div className="column">
                            <h4>Re-activate contract</h4>
                            <p>
                                Are you sure you wish to re-activate the contract?
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <input type="submit" className="danger" value="Re-activate Contract" />
                    </div>
                </form>
            </div>
        )
    }
}
