import React from 'react'
import VacancyList from './VacancyList'

export default class VacancyListMenu extends React.Component {
    constructor(props) {
        super(props)
        this.state = { activeList: 'active', activeJobList: null, inactiveJobList: null, draftsJobList: null }
    }

    componentDidMount() {
        this.onMenuItemClick('active', null)
    }

    onMenuItemClick = (list, e) => {
        if(this.state[list + 'JobList'] == null) {
            fetch(`/vacancies?billing_company_id=${this.props.companyId}&filter=${list}`).then(data => {
                return data.json()
            }).catch(() => {
                console.error('jag fick inte data :(')
            }).then(json => {
                this.setState(prevState => {
                    prevState.activeList = list
                    prevState[list+'JobList'] = json
                    return prevState
                })
            })
        } else {
            this.setState({activeList: list})
        }
    }

    render() {
        return (
            <section>
                <div className="row menu">
                    <div className={`column menu__item ${this.state.activeList == 'active' && 'current'}`} onClick={(e) => this.onMenuItemClick('active', e)}>
                        Active Vacancies ({this.props.vacancyCounts.active})
                    </div>
                    <div className={`column menu__item ${this.state.activeList == 'inactive' && 'current'}`} onClick={(e) => this.onMenuItemClick('inactive', e)}>
                        Inactive Vacancies ({this.props.vacancyCounts.inactive})
                    </div>
                    <div className={`column menu__item ${this.state.activeList == 'drafts' && 'current'}`} onClick={(e) => this.onMenuItemClick('drafts', e)}>
                        Drafts ({this.props.vacancyCounts.drafts})
                    </div>
                </div>
                <VacancyList companyId={this.props.companyId} companyType={this.props.companyType} activeList={this.state.activeList} jobList={this.state[this.state.activeList + 'JobList']} />
            </section>
        )
    }
}
