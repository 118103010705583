import React from 'react'

export default class AddCreditsForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            numberOfMainCredits: 0,
            numberOfProCredits: 0,
            numberOfSmpCredits: 0,
            numberOfDays: 30,
            subscriptionEndDate: this.props.subscriptionEndDate
        }
    }

    onInputChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
          [name]: value
        });
    }

    getProductType() {
        switch (this.props.mainProductId) {
            case 6:
                return 'Subscription'
            default:
                return 'Publish'
        }
    }

    render() {
        return (
            <div className="flex">
                <form action="/purchases" method="POST">
                    <input type="hidden" name="authenticity_token" value={document.getElementsByName('csrf-token')[0].content} />
                    <input type="hidden" name="billing_company_id" value={this.props.companyId} />
                    <input type="hidden" name="mainProductId" value={this.props.mainProductId} />
                    <div className="row">
                        <div className="column">
                            <h4>{this.getProductType()} Credits</h4>
                            <input type="number" name="numberOfMainCredits" onChange={this.onInputChange} value={this.state.numberOfMainCredits} min="0" max="250" step="1" />
                            {this.props.mainProductId == 6 && (
                                <div>
                                    <h4>Subscription End Date</h4>
                                    <input type="date" name="subscriptionEndDate" onChange={this.onInputChange} value={this.state.subscriptionEndDate} />
                                </div>
                            )}
                            {this.props.mainProductId == 1 && (
                                <div>
                                    <h4>Publish Days</h4>
                                    <input type="number" name="numberOfDays" onChange={this.onInputChange} value={this.state.numberOfDays} min="30" max="45" step="1" />
                                </div>
                            )}
                        </div>
                        <div className="column">
                            <h4>Pro Credits</h4>
                            <input type="number" name="numberOfProCredits" onChange={this.onInputChange} value={this.state.numberOfProCredits} min="0" max="50" step="1" />
                        </div>
                        <div className="column">
                            <h4>SMP Credits</h4>
                            <input type="number" name="numberOfSmpCredits" onChange={this.onInputChange} value={this.state.numberOfSmpCredits} min="0" max="50" step="1" />
                        </div>
                    </div>
                    <div className="row">
                        <input type="submit" value="Add Credits" />
                    </div>
                </form>
            </div>
        )
    }
}
