$(document).ready(function () {
    // SEO Section of Category
    $('.accordion-tabs-minimal').each(function(index) {
        $(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show()
    })

    $('.accordion-tabs-minimal').on('click', 'li > a.tab-link', function(event) {
        if (!$(this).hasClass('is-active')) {
            event.preventDefault()
            var accordionTabs = $(this).closest('.accordion-tabs-minimal')
            accordionTabs.find('.is-open').removeClass('is-open').hide()

            $(this).next().toggleClass('is-open').toggle()
            accordionTabs.find('.is-active').removeClass('is-active')
            $(this).addClass('is-active')
        } else {
            event.preventDefault()
        }
    })

    // Show User Page
    $('a.hidden-toggle').on('click', function(e) {
        e.preventDefault()
        e.stopPropagation()

        let $that = $(this)
        if($that.hasClass('active')) {
            $that.removeClass('active')
            $that.text('Show')
            $that.parent().find('em').hide()
        } else {
            $that.addClass('active')
            $that.text('Hide')
            $that.parent().find('em').show()
        }
    })

    $('a#toggle-applications').on('click', function(e) {
        e.preventDefault()
        e.stopPropagation()

        $('ul#applications').toggle()
    })

    // Vacancy Listing Partial
    $('.refresh-vacancy-anchor').each(function bindShowRefreshFormOnClick(i, anchor){
        $(anchor).on('click', function showRefreshFrom(e){
            e.preventDefault()
            var vacancyId = $(this).data().vacancyId
            $("#refresh-vacancy-" + vacancyId).show()
        })
    })

    $('.close-refresh-anchor').each(function bindHideRefreshFormOnClick(i, anchor){
        $(anchor).on('click', function hideRefreshFrom(e){
            e.preventDefault()
            var vacancyId = $(this).data().vacancyId
            $("#refresh-vacancy-" + vacancyId).hide()
        })
    })


    $('.add-days-vacancy-anchor').each(function bindShowRefreshFormOnClick(i, anchor){
        $(anchor).on('click', function showRefreshFrom(e){
            e.preventDefault()
            var vacancyId = $(this).data().vacancyId
            $("#add-days-to-vacancy-" + vacancyId).show()
        })
    })

    $('.close-add-days-anchor').each(function bindHideRefreshFormOnClick(i, anchor){
        $(anchor).on('click', function hideRefreshFrom(e){
            e.preventDefault()
            var vacancyId = $(this).data().vacancyId
            $("#add-days-to-vacancy-" + vacancyId).hide()
        })
    })

    $('.show-publications').on('click', function(e) {
        e.preventDefault()
        var $that = $(this)
        $('#published-list-' + $that.data('id')).show()
    })

    $('.close-publications').on('click', function(e) {
        e.preventDefault()
        var $that = $(this)
        $('#published-list-' + $that.data('id')).hide()
    })

    // UTM Performace statistics page
    $('a.show-campaign-list').on('click', function(e) {
        e.preventDefault()
        var $that = $(this)
        $that.next('.campaign-stats-list').show()
        $that.remove()
    })

    // UTM Performace statistics page
    $('a#show-utm-history').on('click', function(e) {
        e.preventDefault()
        var $that = $(this)
        $('.utm-history').css({display: 'flex'})
        $that.remove()
    })
})
