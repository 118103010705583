import React from 'react'

export default class CompanySearchErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      errorInfo: info
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
          <div>
              <h1>Something went wrong when trying to fetch Companies</h1>
              <p>{this.state.error && this.state.error.toString()}</p>
              <tt>{this.state.errorInfo.componentStack}</tt>
          </div>
      )
    }
    return this.props.children
  }
}