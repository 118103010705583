/* eslint no-console:0 */

import Rails from '@rails/ujs'
import Chart from 'chart.js'

// Validering av formulär
import jQuery from 'jquery'
import Parsley from 'parsleyjs'

import '../src/javascript/old-stuff-refactor'
import '../src/javascript/rails-custom-confirm'
import '../src/javascript/components/CompanySearch'
import '../src/javascript/components/CompanyContainer'
import '../src/javascript/components/Contracts/ContractDashboard'

Rails.start()
