import React from 'react'
import Modal from 'react-modal'
import AddCreditsForm from './AddCreditsForm'
import RemoveCreditsForm from './RemoveCreditsForm'

export default class CompanyCreditsBalance extends React.Component {
    constructor(props) {
        Modal.setAppElement('#react-company-container')

        super(props)
        this.state = {
            modalIsOpen: false,
            removeModalIsOpen: false
        }
    }

    openModal = () => {
        this.setState({modalIsOpen: true})
    }

    closeModal = () => {
        this.setState({modalIsOpen: false})
    }

    openRemoveModal = () => {
        this.setState({removeModalIsOpen: true})
    }

    closeRemoveModal = () => {
        this.setState({removeModalIsOpen: false})
    }

    getCount(productId) {
        if(productId == null) {
            if(this.props.companyType == 'subscription') {
                let node = this.props.unusedCredits.filter(product => product.product_id == 6)[0]
                return node ? node.count : 0
            } else {
                let node = this.props.unusedCredits.filter(product => product.product_id == 1)[0]
                return node ? node.count : 0
            }
        } else {
            let node = this.props.unusedCredits.filter(product => product.product_id == productId)[0]
            return node ? node.count : 0
        }
    }

    getProductName() {
        switch (this.props.companyType) {
            case 'subscription':
                return 'Subscription'
            default:
                return 'Publish'
        }
    }

    render() {
        return (
            <div className="column column__centered">
                <h1>Credits</h1>
                <div className="row">
                    <div className="stats-holder">
                      <em>{this.getCount(null)}</em>
                      <span>{this.getProductName()} Credits</span>
                    </div>
                    <div className="stats-holder">
                      <em>{this.getCount(7)}</em>
                      <span>Pro Addon Credits</span>
                    </div>
                    <div className="stats-holder">
                      <em>{this.getCount(3)}</em>
                      <span>SMP Addon Credits</span>
                    </div>
                </div>
                <div className="row">
                    { !this.props.contractHasEnded && (
                        <div className="column">
                            <a className="button blue" onClick={this.openModal}>Add Credits</a>
                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                contentLabel="Add Credits"
                                className="Modal"
                                overlayClassName="Overlay"
                            >
                                <h3>Add Credits</h3>
                                <AddCreditsForm mainProductId={this.props.mainProductId} companyId={this.props.companyId} subscriptionEndDate={this.props.subscriptionEndDate} />
                            </Modal>
                        </div>
                    )}
                    <div className="column">
                        <a className="button blue" onClick={this.openRemoveModal}>Remove Credits</a>
                        <Modal
                            isOpen={this.state.removeModalIsOpen}
                            onRequestClose={this.closeRemoveModal}
                            contentLabel="Remove Credits"
                            className="Modal"
                            overlayClassName="Overlay"
                        >
                            <h3>Remove Credits</h3>
                            <RemoveCreditsForm mainProductId={this.props.mainProductId} companyId={this.props.companyId} mainProductMaxCount={this.getCount(null)} proMaxCount={this.getCount(7)} smpMaxCount={this.getCount(3)} />
                        </Modal>
                    </div>
                    <div className="column">
                        <a className="button blue" href={this.props.companyId + "/refetch_kadoa_jobs"}>Refetch Kadoa jobs</a>
                    </div>
                    <div className="column">
                        <a className="button blue" href={this.props.companyId + "/refetch_ws_crawler_jobs"}>Refetch WsCrawler jobs</a>
                    </div>
                </div>
            </div>
        )
    }
}
