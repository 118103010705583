import React from 'react'

export default class ContractListItem extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { contract } = this.props

        return (
            <tr>
                <td className={contract.contract_end_date && 'text-yellow-600 font-semibold'}>
                    <strong>{contract.company_name}</strong>
                </td>
                <td className={contract.contract_end_date && 'text-yellow-600 font-semibold'}>
                    <strong>{contract.contract_end_date && contract.contract_end_date.slice(0,10)}</strong>
                </td>
                <td className="tight text-right">
                    <a href={`/billing_companies/${contract.id}`}>Show</a>
                </td>
            </tr>
        )
    }
}
